<template>
  <section v-if="user.information">

    <b-card no-body no-header>
      <b-card-body class="p-2">
        {{ $t('SUPPORT_INFORMATION') }}
      </b-card-body>
    </b-card>

    <b-card no-body no-header>
      <b-card-body class="p-2">
        
        {{ $t('SUPPORT.FORUM_DESCRIPTION') }}
        <br />
        <a target="_blank" href="https://forum.sefos.se">{{ $t('SUPPORT.FORUM_BTN')}}</a>
      </b-card-body>
    </b-card>


      <b-card no-body class="mt-2">
        <template v-slot:header>
          {{ $t("INFORMATION") }}
        </template>
        <b-card-body class="p-0">
          <dl class="clearfix pb-2">
            <dt>{{ $t("NAME") }}</dt>
            <dd>{{ user.information.name }}</dd>
            <dt>{{ $t("EMAIL") }}</dt>
            <dd>{{ user.information.email }}</dd>
            <dt>User Uuid</dt>
            <dd>{{ user.information.user_uuid }}</dd>
            <dt>Organisation Uuid</dt>
            <dd>{{ user.information.user_uuid.split(":")[0] }}</dd>
            <dt>{{ $t("LOA.NAME") }}</dt>
            <dd>{{ user.information.loa }}</dd>
            <dt>Version</dt>
            <dd>{{ currentVersion }}</dd>
            <dt>Server</dt>
            <dd>{{ serverUrl }}</dd>
          </dl>
        </b-card-body>
      </b-card>

    </section>
</template>
<script>
export default {
  data() {
    return {
      server_version: "",
      commit_hash: process.env.VUE_APP_COMMIT_HASH,
      package_version: process.env.VUE_APP_PACKAGE_VERSION,
      serverUrl: "https://" + window.location.hostname,
    };
  },
  methods: {
    async getServerVersion() {
      if (this.$route.meta.showVersion) {
        let self = this;
        this.$http.get(this.user.hostname + "/version").then(function (result) {
          self.server_version = result.data;
        });
      }
    },
  },
  computed: {
    currentVersion() {
      return (
        "v." +
        this.commit_hash +
        "-" +
        this.package_version +
        "-" +
        this.server_version
      );
    },
  },
  mounted() {
    this.getServerVersion();
  },
};
</script>
<style></style>
